<template>
  <div class="page-spec-agent-list">
    <spec/>
  </div>
</template>

<script lang="js">
import SpecAgentListPage from './spec-agent-list';

export default SpecAgentListPage;
</script>

<style lang="scss">
@import "./spec-agent-list";
</style>
