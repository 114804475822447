import { Component, Vue } from 'vue-property-decorator';
import Spec from '../spec-merchant-list/spec-merchant-list.vue'

@Component({
  components: { Spec }
})
class SpecAgentListPage extends Vue {
  title = 'spec-agent-list';
}

export default SpecAgentListPage;
